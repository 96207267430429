<template>
  <main class="translation page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="lang-title weight-700">{{ $t("REPORT_SALES.SALES_REPORT") }}</p>
    <div class="filter-part weight-700">
      <h4 class="weight-700">{{ $t("REPORT_SALES.TIME_FILTERING") }}</h4>
      <b-row>
        <b-col lg="4" class="px15">
          <b-row>
            <b-col lg="5" class="d-flex align-items-center label-align p10">
              {{ $t("REPORT_SALES.FROM_DATE") }}:
            </b-col>
            <b-col lg="7" class="d-flex align-items-center p10">
              <Datepicker 
                v-model="filter.from_date" 
                :format="format"
                class="input tour-person__input cus-datepicker"
                :language="languages[currentLang]"
              ></Datepicker>
              <img src="/assets/img/calander.png" alt="" class="calendar-img">
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="4" class="px15">
          <b-row>
            <b-col lg="5" class="d-flex align-items-center label-align p10">
              {{ $t("REPORT_SALES.UNTIL") }}:
            </b-col>
            <b-col lg="7" class="d-flex align-items-center p10">
              <Datepicker 
                v-model="filter.until" 
                :format="format"
                class="input tour-person__input cus-datepicker"
                :language="languages[currentLang]"
              ></Datepicker>
              <img src="/assets/img/calander.png" alt="" class="calendar-img">
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="4" class="px15">
          <b-row>
            <b-col lg="4" class="d-flex align-items-center label-align p10">
              {{ $t("REPORT_SALES.START_HOUR") }}:
            </b-col>
            <b-col lg="8" class="d-flex align-items-center p10">
              <vue-select 
                :options="hours.options" 
                class="input cus-select time-select"
                v-model="hours.selected"
              >
              </vue-select> 
              <span>:</span> 
              <vue-select 
                :options="mins.options" 
                class="input cus-select time-select"
                v-model="mins.selected"
              >
              </vue-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4" class="d-flex align-items-center label-align p10">
              {{ $t("REPORT_SALES.END_HOUR") }}:
            </b-col>
            <b-col lg="8" class="d-flex align-items-center p10">
              <vue-select 
                :options="hours.options" 
                class="input cus-select time-select"
                v-model="hours.selected"
              >
              </vue-select> : 
              <vue-select 
                :options="mins.options" 
                class="input cus-select time-select"
                v-model="mins.selected"
              >
              </vue-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="8" class="px15">
          <b-row>
            <b-col lg="4" class="d-flex align-items-center">
              <v-checkbox v-model="filter.isTimeLimit" color="#0D3856" class="cus-checkbox pt5 mx5 inline-checkbox"></v-checkbox>
              <label for="" class="m-0">{{ $t("REPORT_SALES.WITHOUT_TIME_LIMITS") }}</label>
            </b-col>
            <b-col lg="8" class="d-flex align-items-center sales-range-date">
              <label for="" class="m-0">{{ $t("REPORT_SALES.RANGE_OF_DATES") }}</label>
              <date-range-picker v-model="filter.dateRange" class="range-datepicker mx10 px10">
              </date-range-picker>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="4" class="px15"></b-col>
      </b-row>
    </div>
    <div class="filter-part m-t-10 weight-700">
      <h4 class="weight-700">{{ $t("REPORT_SALES.GENERAL_FILTERING") }}</h4>
      <b-row>
        <b-col lg="3" class="px15">
          <b-row>
            <b-col lg="5" class="d-flex align-items-center label-align p10">
              {{ $t("REPORT_SALES.SUPPLIER_ID") }}:
            </b-col>
            <b-col lg="7" class="d-flex align-items-center p10">
              <input class="input tour-person__input filter-width" type="text" v-model="filter.doubt" />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="4" class="px15">
          <b-row>
            <b-col lg="5" class="d-flex align-items-center label-align p10">
              {{ $t("TOURISM_PROFIT_PERCENTAGES.MOBILE_PHONE") }}:
            </b-col>
            <b-col lg="7" class="d-flex align-items-center p10">
              <input class="input tour-person__input filter-width" type="text" v-model="filter.doubt" />
              <div class="icon-div">
                ?
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="5" class="px15">
          <b-row>
            <b-col lg="4" class="d-flex align-items-center label-align p10">
              {{ $t("REPORT_SALES.FORM_OF_PAYMENT") }}:
            </b-col>
            <b-col lg="8" class="d-flex align-items-center p10">
              <vue-select 
                :options="filter.paymentForm.options" 
                class="input cus-select filter-width"
                v-model="filter.paymentForm.selected"
              >
              </vue-select> 
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <button class="primary-btn search_btn">{{ $t("REPORT_SALES.SEARCH") }}</button>
    <div class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t("COMMON.SEARCH") }}</p>
          <v-text-field v-model="search" single-line hide-details class="search-form"></v-text-field>
        </div>
        <div class="total-value">
          <span>{{ $t("TOURISM_REPORT.TOTAL") }}: </span>  65,768.36
        </div>
      </div>
      
      <v-data-table
        :headers="headers"
        :items="sales_data"
        class="cus-table editable-table sales-table striped-table"
        :search="search"
      >
        <template v-slot:[`item.detail`]="{ item }">
          <span class="color-blue1">
            {{ item.detail }}
          </span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div class="ticket-item bg-4" v-if="item.status == 'הושלם'">
            {{ item.status }}
          </div>
          <div class="ticket-item bg-2" v-else-if="item.status == 'בהזמנה'">
            {{ item.status }}
          </div>
          <div class="ticket-item bg-red" v-else-if="item.status == 'אזל'">
            {{ item.status }}
          </div>
        </template>
      </v-data-table>

    </div>
    <div class="action-part d-flex align-items-center">
      <div class="d-flex align-items-center">
        <img src="/assets/img/print.png" alt="" class="print-img hover-pointer mx15">
        <label for="">{{ $t("REPORT_SALES.FOR_PRINTING") }}</label>
      </div>
      <div class="d-flex align-items-center">
        <img src="/assets/img/excel.png" alt="" class="excel-img hover-pointer">
        <label for="">{{ $t("REPORT_SALES.EXPORT_TO_EXCEL") }}</label>
      </div>
    </div>
  </main>
</template>

<script>
import "bootstrap";
import "../../common/plugins/bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "TourismReport",
  components: {
  },  
  computed: {
    currentLang: function() {
      return localStorage.language;
    }
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);
    return {
      search: "",
      visible: false,
      headers: [
        {
          text: this.$t("REGISTER_RESELLER.BUSINESS_NAME"),
          align: "center",
          sortable: false,
          value: "business_name"
        },
        {
          text: this.$t("REPORT_SALES.MOBILE"),
          align: "center",
          sortable: false,
          value: "mobile"
        },
        {
          text: this.$t("TOURISM_REPORT.TRANSACTION_DETAILS"),
          align: "center",
          sortable: false,
          value: "detail"
        },
        {
          text: this.$t("TOURISM_REPORT.PURCHASE_DATE"),
          align: "center",
          sortable: false,
          value: "purchase_date"
        },
        {
          text: this.$t("COMMON.HOUR"),
          align: "center",
          sortable: false,
          value: "hour"
        },
        {
          text: this.$t("REPORT_SALES.CONSUMER_PRICE"),
          align: "center",
          sortable: false,
          value: "consumer_price"
        },
        {
          text: this.$t("REPORT_SALES.STATUS"),
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: this.$t("TOURISM_REPORT.PAYMENT"),
          align: "center",
          sortable: false,
          value: "payment"
        },
        {
          text: this.$t("TOURISM_REPORT.A_CONFIRMATION"),
          align: "center",
          sortable: false,
          value: "confirmation"
        },
        {
          text: this.$t("TOURISM_REPORT.NO_OF_PASSENGERS"),
          align: "center",
          sortable: false,
          value: "passengers_no"
        },
        {
          text: this.$t("REGISTER_RESELLER.REMARKS"),
          align: "center",
          sortable: false,
          value: "remarks"
        }
      ],
      sales_data: [
        {
          business_name: "הפיצוציה",
          mobile: "0543095861",
          detail: "...חבילת נופש בברצלונה",
          purchase_date: "28-01-2019",
          hour: "23:11:52",
          consumer_price: "59",
          cost_price: "-54.21",
          status: "הושלם",
          payment: "מזומן",
          confirmation: "28-01-2019",
          passengers_no: "4",
          hosting: "ארוחת בוקר",
          passengers_number: "4",
          remarks: ""
        },
        {
          business_name: "הפיצוציה",
          mobile: "0543095860",
          detail: "אמזון",
          purchase_date: "28-01-2019",
          hour: "23:11:52",
          consumer_price: "59",
          cost_price: "-54.21",
          status: "בהזמנה",
          payment: "מזומן",
          confirmation: "28-01-2019",
          passengers_no: "4",
          hosting: "ארוחת בוקר",
          passengers_number: "4",
          remarks: ""
        }
      ],
      filter: {
        from_date: "",
        until: "",
        isTimeLimit: false,
        dateRange: { startDate, endDate },
        doubt: this.$t('EVERYTHING'),
        paymentForm: {
          selected: this.$t('EVERYTHING'),
          options: ["Onething", "something"]
        }
      },
      hours: {
        options: ["01", "02", "03"],
        selected: "01"
      },
      mins: {
        options: ["01", "02", "03"],
        selected: "01"
      },
      format: "d MMMM yyyy",
      expanded: []
      
    };
  },
  validations: {
    addForm: {
      group: {
        required
      },
      key: {
        required
      },
      en: {
        required
      },
      he: {
        required
      },
      ar: {
        required
      }
    }
  },
  async created() {
    if (this.lang_data == "") {
      await this.getLangData();
    }
  },
  methods: {
    ...mapActions("language", {
      getLangData: "getAllData",
      addLangData: "addLangData",
      delLangData: "delLangData",
      updateLangData: "updateLangData"
    }),

    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addForm[name];
      // debugger;
      return $dirty ? !$error : null;
    }
  }
};
</script>

<style lang="scss">
.time-select{
  width: 70px;
  .vs__selected {
  height: 100%;
  top: 0;
  }
  .vs__actions {
    margin-right: -10px;
  }
  .vs__dropdown-menu {
    min-width: 70px;
    max-width: 70px;
  }
}
.ltr-type {
  .time-select{
    .vs__actions {
      margin-right: 0;
    }
  }
}
.cus-select {
  ul {
    padding-left: 0;
  }
} 
.inline-checkbox .v-input__control {
  height: 100%;
}
.sales-range-date {
  .range-datepicker {
    width: 260px;
    .reportrange-text {
      border-radius: 8px;
      span {
        font-size: 18px;
        color: #002036;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.row {
  margin: 0 !important;
}
.translation {
  height: 100%;
  color: #002036;
}

.lang-title {
  font-size: 30px;
  margin-top: 10px;
  text-align: center;
}

.filter-part {
  border: 1px solid #707070;
  margin: 20px 50px 0 50px;
  padding: 10px 40px;
  .input {
    height: 40px;
    padding: 0 10px;
    border-radius: 8px;
  }
  .calendar-img {
    margin: 0 5px;
    width: 35px;
  }
  .v-input__control {
    height: 100%;
  }
}

.total-value {
  font-weight: 600;
  color: #707070;
  font-size: 24px;
  span {
    font-size: 20px;
    color: #002036;
  }
}
.sales-table {
  .ticket-item {
    border: 1px solid #707070;
    color: #fff;
    border-radius: 5px;
    width: 70px;
    margin: 0 auto;
  }
  .print-img {
    width: 40px;
  }
}
.label-align {
  justify-content: flex-end;
}
.icon-div {
  background-color: #002036;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 0 10px;
}

.search_btn {
  margin: 0 auto;
  margin-top: 20px;
}
.expand-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  p {
    margin: 10px 0;
  }
  .full-detail_btn {
    background-color: #fff;
    color: #000;
    font-weight: 700;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 7px 20px;
  }
}
.action-part {
  margin: 20px 70px;
  .print-img {
    width: 50px;
  }
  .excel-img {
    width: 60px;
    margin-left: 50px;
    margin-right: 15px;
  }
  label {
    font-weight: 700;
    margin: 0;
  }
}
.rtl-type {
  label-align {
    justify-content: flex-start;
  }
  .excel-img {
    margin-right: 50px;
    margin-left: 15px;
  }
}
@media screen and (min-width: 992px){
  .filter-width {
    max-width: 200px;      
  }
}
@media screen and (max-width: 992px){
  .label-align {
    justify-content: flex-start;
    padding-bottom: 0 !important;
  }
  .rtl-type .label-align {
    justify-content: flex-end;
    padding-bottom: 0 !important;
  }
}

</style>